import React from "react";
import get from "lodash/get";
import { graphql, Link } from "gatsby";

import * as styles from "../styles/whitepaper.module.scss";

import Container from "../components/global/container";
import Seo from "../components/global/seo";
import TokenomicsPie from "../components/tokenomics-pie-chart/tokenomics-pie-chart";
import Footer from "../components/footer/footer";
import Logo from "../../static/logo.svg";
import FlexSpace from "../components/global/flex-space";

class Whitepaper extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;
        return (
            <>
                <Seo
                    title={`Whitepaper | ${meta.siteMetadata.title}`}
                    description={""}
                />
                <header className={styles.whitepaperHeader}>
                    <Container>
                        <div className="d-flex justify-content-center justify-content-md-between">
                            <Link to={"/"} className={styles.brandLink}>
                                <FlexSpace>
                                    <Logo className={styles.brandLogo} />
                                    <h1 className={styles.brandName}>
                                        Unifyd Finance
                                    </h1>
                                </FlexSpace>
                            </Link>
                        </div>
                    </Container>
                </header>
                <article className="mb-60">
                    <section className="background-lighter-gray">
                        <Container>
                            <div className="row">
                                <div className="col-12 py-60">
                                    <h1 className="font-weight-bold line-height-3 font-size-xl">
                                        A Next-Gen Community Backed DeFi
                                        Ecosystem
                                    </h1>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section>
                        <Container>
                            <div className="row border-bottom-light-2 py-60">
                                <div className="col-12 mb-60">
                                    <h2 className="mb-10">Version 1.0</h2>
                                    <p>June 2021</p>
                                </div>
                                <div className="col-12">
                                    <h2 className="mb-10">Author</h2>
                                    <p>Dean Sharon</p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Abstract */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="font-weight-bold line-height-3 font-size-lg mb-60">
                                        Abstract
                                    </h2>
                                    <p>
                                        Today, almost all of our financial
                                        services are controlled by banks, from
                                        account management and payments to loans
                                        and investments. As a result, the
                                        problems with the current system are
                                        increasingly becoming evident to all of
                                        us:
                                    </p>
                                    <ul>
                                        <li className="mb-10">Censorship</li>
                                        <li className="mb-10">
                                            Custodial Management
                                        </li>
                                        <li className="mb-10">
                                            Compounded costs and fees due to
                                            middle(wo)men
                                        </li>
                                        <li className="mb-10">
                                            Slow transactions
                                        </li>
                                        <li className="mb-10">
                                            Limited Investment Products
                                        </li>
                                        <li>
                                            Inaccessibility to many sectors of
                                            the population
                                        </li>
                                    </ul>
                                    <p>
                                        The good news is that it is finally
                                        possible for us to take our financial
                                        future into our own hands and change
                                        this system. Finally, we can break
                                        through the walled gardens of
                                        traditional finance and create a new
                                        system that works for us, by us. We got
                                        so used to banks managing our money, but
                                        the truth is we don't need them anymore.
                                        They use it to get richer, and we don't
                                        see any of it. That is all about to
                                        change.
                                        <br />
                                        <br />
                                        Decentralized Finance Applications
                                        (DeFi) have taken the crypto world by
                                        storm since their inception. DeFi can be
                                        defined as the transformation of
                                        traditional financial products into
                                        products that operate without an
                                        intermediary via smart contracts on a
                                        blockchain. In principle, any existing
                                        financial service that is centralized
                                        could be transferred to a similar
                                        decentralized financial service.
                                        Unfortunately, while crypto has
                                        attracted billions in investments,
                                        decentralized financial services are
                                        still lagging.
                                        <br />
                                        <br />
                                        Initial attempts to create decentralized
                                        financial management systems so far have
                                        proven partial and unreliable. Hence,
                                        average users and investors have to
                                        man-hand highly complex products to
                                        manage, invest and trade their crypto
                                        assets. Nevertheless, the potential to
                                        provide financial services in crypto,
                                        the same way they are offered in fiat
                                        currency and traditional banking, is
                                        enormous.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Introduction */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Introduction</span>
                                    </h2>
                                    <p>
                                        Unifyd is next-generation one-stop
                                        management, optimization, and automation
                                        ecosystem for decentralized finance
                                        products, investments, payments, and
                                        wallets focused on security,
                                        accessibility, and adoption, making it
                                        possible for anyone to truly be their
                                        own bank. Unifyd is the first platform that
                                        genuinely creates a completely open and
                                        fair ecosystem lead by the community of
                                        its token holders.
                                        <br />
                                        <br />
                                        Unifyd simplifies and aggregates all the
                                        pieces of the DeFi puzzle into one
                                        coherent interface in a way that even
                                        inexperienced users and network
                                        participants can understand. From
                                        lending protocols and liquidity mining
                                        to streamlining staking and delegating
                                        assets.
                                        <br />
                                        <br />
                                        Unifyd is the first protocol to place
                                        fundamental emphasis on security and
                                        offers a dedicated fund to insure and
                                        cover potential losses of network
                                        participants. In addition, after every
                                        single change, every smart contract is
                                        verified and audited by top security
                                        firms and is published to the community
                                        and peer security experts for review.
                                        <br />
                                        <br />
                                        The cryptocurrency industry is based on
                                        a simple premise: people should be fully
                                        in control of their finances. However,
                                        current systems and apps are far from
                                        providing services that bolster wide
                                        adoption and provide a solution for
                                        average users with daily money
                                        management worries like figuring out
                                        their income, expenses, balance, and
                                        assets managed on autopilot.
                                        <br />
                                        <br />
                                        Unifyd's mission is to give people (and in
                                        the future, machines, and devices)
                                        seamless access to decentralized
                                        financial services in a way that
                                        supports our daily lives—helping the
                                        next wave of users onboard the open
                                        financial ecosystem.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Problems & Motivation */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Problems & Motivation</span>
                                    </h2>
                                    <ol className="list-style-decimal-leading-zero">
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Financial Services Are
                                                Centralized
                                            </strong>
                                            <p>
                                                Our most fundamental motivation
                                                and driving force is to create a
                                                fair and open financial system
                                                for all of us, where we are the
                                                ones in control of our finances.
                                                The most pressing issue of our
                                                growing global economy is that
                                                it is centralized and entirely
                                                controlled by a small group of
                                                people.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Advanced DeFi Products Are Not
                                                Easily Accessible
                                            </strong>
                                            <p>
                                                Advanced decentralized products
                                                such as auto-compounding vaults,
                                                composing conditional trades,
                                                staking, flash loan arbitrage,
                                                and flash liquidations are only
                                                accessible to experienced
                                                developers. As an average user
                                                or investor, these types of
                                                products are out of reach. You
                                                can't fully enjoy their benefits
                                                to grow your wealth and put your
                                                assets to work for you in an
                                                optimal composition according to
                                                your current holdings.
                                                Non-developers are excluded from
                                                some of the most innovative
                                                solutions of DeFi, and as it
                                                evolves, the gap is just going
                                                to grow bigger.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Users' Assets Are Scattered On
                                                Multiple Wallets, Protocols, And
                                                Exchanges
                                            </strong>
                                            <p>
                                                The DeFi ecosystem is evolving
                                                at such a high speed it's hard
                                                for most users to even keep up
                                                with all the new tools and
                                                building blocks that emerge
                                                daily. Users new to crypto and
                                                even the more savvy users
                                                struggle to understand it all
                                                and manage their assets to get
                                                optimal returns. Even though
                                                there are solutions to help
                                                optimize yield, the experience
                                                is not easy for new users. They
                                                do not provide holistic,
                                                autopilot products where average
                                                users and savvy investors can
                                                easily manage their assets,
                                                wallets, and portfolios from a
                                                to z through one point of
                                                access.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Existing Protocols Lack Security
                                                Mechanisms To Ensure Users'
                                                Safety
                                            </strong>
                                            <p>
                                                Security in DeFi is probably the
                                                most significant concern that
                                                keeps users from venturing out
                                                of centralized exchanges and
                                                entirely relying on
                                                decentralized solutions. It
                                                seems that every other day
                                                another DeFi protocol is hacked
                                                for millions costing its users
                                                and investors losses that
                                                sometimes obliterate their
                                                entire holdings. While some
                                                projects offer different
                                                insurance modules and go through
                                                security code audits, these
                                                solutions do not provide enough
                                                coverage and safety.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold">
                                            <strong className="d-block mb-10">
                                                DeFi Is Too Complex For New
                                                Users
                                            </strong>
                                            <p>
                                                Crypto never sleeps, volatility
                                                is high and unpredictable,
                                                staying on top of your portfolio
                                                and optimizing your returns is
                                                complex if you want to do
                                                anything other than looking at
                                                charts and comparing strategies
                                                all day long. While some
                                                products offer automated yield
                                                optimization and generation,
                                                their solutions are either too
                                                pricey, incomplete, or too
                                                complex for new users to
                                                understand. If we are going to
                                                change our financial system
                                                truly, DeFi applications must
                                                take a simple, easy-to-use form
                                                to allow all user types to
                                                manage their finances and go
                                                about their daily lives.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Proposed Solution */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Proposed Solution</span>
                                    </h2>
                                    <p className="mb-60">
                                        An ever-growing set of advanced
                                        community-backed DeFi products that
                                        together comprise a complete financial
                                        ecosystem and a single point of access
                                        management portal for crypto assets.
                                        Where users can automate their entire
                                        monetary management and optimize their
                                        yields without deep know-how, some of
                                        the first proposed components are:
                                    </p>
                                    <ol className="list-style-decimal-leading-zero mb-60">
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Yield Optimization Vaults
                                            </strong>
                                            <p>
                                                Capital pools that automatically
                                                generate and optimize yield
                                                powered by Unifyd's AI. Unifyd's AI
                                                Vaults socialize gas costs,
                                                automate yield generation,
                                                auto-compounding, re-balancing,
                                                and automatically shift capital
                                                as the market changes to
                                                generate passive income.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Index Tokens
                                            </strong>
                                            <p>
                                                Like traditional markets, as the
                                                crypto market matures, it too
                                                requires easy-to-use products
                                                that serve as benchmarks and
                                                entry points into crypto. We
                                                believe that if we overlay the
                                                opportunity in the crypto world,
                                                crypto ETFs have the potential
                                                to make up a significant portion
                                                of the crypto market, and we’re
                                                here to make that vision a
                                                reality.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Multi-Asset Swaps
                                            </strong>
                                            <p>
                                                Swapping assets is one of the
                                                most fundamental actions in
                                                DeFi. However, this manual
                                                process can quickly become
                                                time-consuming when you have
                                                multiple assets to swap one by
                                                one. Unifyd's Multi-Asset Swaps
                                                allow users to trade and
                                                transact in an unlimited amount
                                                of assets, all in a single
                                                action.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Community Run Staking Nodes
                                            </strong>
                                            <p>
                                                How many times did you find
                                                yourself wishing you had enough
                                                funds or knowledge to run a node
                                                for your favorite network? As a
                                                community member, it's finally
                                                possible. Staking your coins
                                                while you hold them is one of
                                                the safest ways to earn interest
                                                on your cryptos.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Conditionally Composable
                                                Transactions
                                            </strong>
                                            <p>
                                                Creating future executable
                                                transactions based on on-chain
                                                and live real-world data, and
                                                publish your trading strategies
                                                to the entire community for
                                                others to join you. For example:
                                                IF ([$BTC is 50% from ATH] &
                                                [$BTC is down 25% Today] &
                                                [$MATIC is down 45% Today]) →
                                                buy $MATIC. Then IF ($MATIC is
                                                up 80% Today) → sell $MATIC.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Portfolio Management
                                            </strong>
                                            <p>
                                                Manage your DeFi portfolio using
                                                dedicated dashboards, plug in
                                                your data with APIs, track
                                                changes per asset from time of
                                                purchase and based on other
                                                advanced filters. The portfolio
                                                management dashboard will
                                                quickly become your go-to tool
                                                for managing and tracking all
                                                your actions.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Flash Loan Bots
                                            </strong>
                                            <p>
                                                Community members and $UNIFY token
                                                holders will enjoy a profit
                                                share from gains earned by
                                                ecosystem bots such as a flash
                                                arbitrage algo bot, a flash
                                                liquidation algo bot, a trading
                                                bot, etc. These bots will
                                                generate profits for the $UNIFY
                                                token holders without using any
                                                of their own funds.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold">
                                            <strong className="d-block mb-10">
                                                Leverage Optimization Algo
                                            </strong>
                                            <p>
                                                Hook up your leveraged trades to
                                                our leverage optimization algo
                                                can automate and manage your
                                                leveraged positions to protect
                                                them from liquidation based on
                                                customizable settings in an
                                                entirely non-custodial and
                                                trustless way.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Network Security */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Network Security</span>
                                    </h2>
                                    <p className="mb-60">
                                        Our protocol establishes a thorough
                                        user-centric approach to network
                                        security to answer the growing need for
                                        security and safety of user funds when
                                        dealing with DeFi, which can answer
                                        varying security needs as the protocol
                                        matures in the future.
                                    </p>
                                    <ol className="list-style-decimal-leading-zero">
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Insurance Fund
                                            </strong>
                                            <p>
                                                The primary mechanism for
                                                securing the Unifyd Protocol is the
                                                Insurance Fund. A percentage of
                                                the fees earned by the different
                                                products is accumulated to a
                                                fund that will be used to
                                                compensate and protect users of
                                                the protocol from unexpected
                                                loss of funds stemming from:
                                                <br />
                                                <br />
                                            </p>
                                            <ul>
                                                <li>
                                                    <strong>
                                                        Smart contract risk:
                                                    </strong>{" "}
                                                    Risk of a bug, design flaw,
                                                    or potential attack surfaces
                                                    on the smart contract layer.
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>
                                                        Liquidation risk:
                                                    </strong>{" "}
                                                    Risk of failure of an asset
                                                    that is being used as
                                                    collateral on Unifyd; risk of
                                                    liquidators not capturing
                                                    liquidation opportunities on
                                                    time. Or low market
                                                    liquidity of the principal
                                                    asset to be repaid.
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>
                                                        Oracle failure risk:
                                                    </strong>{" "}
                                                    Risk of the Oracle system
                                                    not correctly updating the
                                                    prices in an extreme market
                                                    downturn and network
                                                    congestion; risk of the
                                                    Oracle system not correctly
                                                    submitting prices, causing
                                                    improper liquidations.
                                                </li>
                                            </ul>
                                            <br />
                                            <p>
                                                The size of the fund is decided
                                                based on the volume and total
                                                value locked in the platform and
                                                is accumulated intermittently.
                                                The first milestone for the fund
                                                is 20 million $USDT.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Safety Module
                                            </strong>
                                            <p>
                                                The secondary mechanism for
                                                securing the Unifyd Protocol is
                                                incentivizing $UNIFY holders to
                                                lock tokens into a Smart
                                                Contract-based Safety Module
                                                component. The locked $UNIFY will
                                                be used as a second mitigation
                                                tool if the Insurance Fund is
                                                not enough to cover everyone's
                                                losses. The activation of this
                                                tool is subject to a Governance
                                                vote. The Safety Module can use
                                                only up to 20% of the assets
                                                locked to cover the deficit.
                                                <br />
                                                <br />
                                                Participants' decision to lock
                                                $UNIFY into the Safety Module
                                                assumes the acceptance of a
                                                potential loss of funds event as
                                                they secure the protocol in
                                                return for receiving rewards in
                                                the form of Safety Incentives
                                                and fee distributions.
                                                <br />
                                                <br />
                                                To contribute to the protocol's
                                                safety and receive incentives,
                                                $UNIFY holders will deposit their
                                                tokens into the Security Module.
                                                In return, they will receive
                                                governance tokens (See
                                                'Governance' for additional
                                                information) and a tokenized
                                                position that can be freely
                                                moved within the underlying
                                                network. The holder of the
                                                tokenized position can redeem
                                                their share from the Security
                                                Module at any time, triggering a
                                                cooldown period of one week.
                                                Safety Incentives rewards are
                                                subject to a cooldown period
                                                where tokens are unclaimable.
                                                The cooldown period is set to
                                                seven days.
                                                <br />
                                                <br />
                                                The Safety Module is built on
                                                top of existing AMM
                                                technologies. Users will have
                                                the choice to lock single $UNIFY
                                                tokens directly or provide
                                                liquidity shares from the 80/20
                                                UNIFY/ETH Balancer Pool.
                                                <br />
                                                <br />
                                                The Safety Module solves the
                                                issues with traditional staking
                                                systems and market liquidity:
                                                Tokens with locking/reward
                                                schemes tend to suffer from low
                                                market liquidity and extreme
                                                volatility when a high
                                                percentage of the total supply
                                                is locked. With the ability to
                                                contribute to the Safety Module
                                                by locking $UNIFY and contributing
                                                with liquidity into an AMM,
                                                stakers create a trustless and
                                                decentralized market with deep
                                                liquidity for trading $UNIFY
                                                against ETH.
                                                <br />
                                                <br />
                                                The advantage of using Balancer
                                                against other AMM solutions is
                                                evident when we factor in the
                                                possibility of dynamically
                                                adjusting the weighting towards
                                                $UNIFY. This allows the creation
                                                of a market and the provision of
                                                liquidity while maintaining
                                                exposure to $UNIFY. Holding
                                                UNIFY/ETH liquidity with uneven
                                                weights is very close to simply
                                                holding $UNIFY, with the benefits
                                                of earning trading fees on top
                                                of it.
                                                <br />
                                                <br />
                                                Since Balancer Labs is
                                                distributing $BAL governance
                                                tokens to liquidity providers,
                                                having the Safety Module
                                                liquidity in Balancer enables
                                                the users to receive $BAL tokens
                                                on top of trading fees, protocol
                                                fees, and Safety Incentives
                                                rewards.
                                                <br />
                                                <br />
                                                Allowing users to lock single
                                                $UNIFY tokens enables us to free
                                                up less advanced users from the
                                                burden of providing two
                                                different assets to participate
                                                in staking. In addition, the
                                                Safety Module will support
                                                weighted incentives to attract
                                                liquidity in case of AMM
                                                imbalance.
                                                <br />
                                                <br />
                                                The Safety Module mechanism is
                                                reinforced with a built-in
                                                Backstop Module. This module is
                                                a smart contract-based deposit
                                                pool to allow the Unifyd Community
                                                to deposit stablecoins and $ETH
                                                acting as a buy order for the
                                                $UNIFY token at a price agreed on
                                                by the protocol governance in
                                                the case of a capital loss
                                                event, to act as a buyer of last
                                                resort.
                                                <br />
                                                <br />
                                                Back-stoppers are incentivized
                                                to have liquidity in the
                                                Backstop, as protocol fees are
                                                shared with them. In case of
                                                Backstop buyback occurrence, the
                                                $UNIFY purchased by the Backstop
                                                is distributed proportionally to
                                                backstop LPs, or they can
                                                directly deposit back their
                                                newly acquired $UNIFY in the
                                                staking module.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold mb-40">
                                            <strong className="d-block mb-10">
                                                Routine Audits
                                            </strong>
                                            <p>
                                                One of the shortfalls of
                                                security audits is that in most
                                                cases, protocol developers make
                                                additional code changes after an
                                                audit already took place,
                                                rendering the audit useless.
                                                This bad practice alone is the
                                                cause for many of the latest
                                                DeFi hacks and exploits. As part
                                                of our focus on security, our
                                                team commits to routine audits
                                                for every set of changes we make
                                                to the protocol's underlying
                                                code. This ensures the code we
                                                ship is as battle-tested and
                                                proven as possible. In addition,
                                                we make sure multiple firms
                                                audit every contract we ship.
                                                Among the experts we work with
                                                are the team at CertiK,
                                                PeckShield, Open Zeppelin,
                                                SigmaPrime, Consensys Diligence,
                                                and more.
                                            </p>
                                        </li>
                                        <li className="font-weight-bold">
                                            <strong className="d-block mb-10">
                                                Bug Bounty Programs
                                            </strong>
                                            <p>
                                                An integral part of our
                                                innovation process is research
                                                and peers reviews. We seek
                                                cooperation with experts in
                                                their fields for every aspect of
                                                our ecosystem, focusing on
                                                research and creating
                                                state-of-the-art technology. For
                                                example, our Bug Bounty programs
                                                incentivize white hat hackers to
                                                break our code and actively
                                                support our efforts to attract
                                                talent to our ecosystem. In
                                                addition, we partnered with the
                                                team at Immunefi to expose our
                                                programs to a broader hacker
                                                community.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Governance */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Governance</span>
                                    </h2>
                                    <h3 className="font-weight-bold line-height-3 font-size-md">
                                        Protocol Governance
                                    </h3>
                                    <br />
                                    <br />
                                    <p>
                                        The governance token for the Unifyd
                                        Ecosystem is Epsilon ($PSI). $PSI tokens
                                        can only be earned by staking $UNIFY
                                        tokens in the Safety Module. Staked $UNIFY
                                        tokens accrue interest in real-time in
                                        the form of newly minted $PSI tokens.
                                        $PSI tokens entitle their owners with
                                        governance powers and profit share
                                        rights.
                                        <br />
                                        <br />
                                        In general, the Unifyd Ecosystem governance
                                        consists of the proposition and
                                        decision-making process for the
                                        different changes, improvements, and
                                        incentives that constitute the policies
                                        and upgrades to the governance mechanism
                                        itself. All future decisions governing
                                        the ecosystem will be enacted through
                                        this procedure.
                                        <br />
                                        <br />
                                        The $PSI token empowers holders to
                                        collectively act as governors of the
                                        ecosystem by enabling them with the
                                        capability to vote and propose.
                                        <br />
                                        <br />
                                        This ensures the ecosystem can rapidly
                                        adjust to changing market conditions and
                                        upgrade core parts of the ecosystem as
                                        time goes on.
                                    </p>
                                    <br />
                                    <br />
                                    <h3 className="font-weight-bold line-height-3 font-size-md">
                                        Governance Power
                                    </h3>
                                    <br />
                                    <br />
                                    <p>
                                        $PSI token holders receive governance
                                        powers proportionally to the sum of
                                        their balance.
                                        <br />
                                        <br />
                                        There are initially two powers
                                        associated with each governance token:
                                    </p>
                                    <br />
                                    <ul>
                                        <li className="mb-20">
                                            The <strong>proposal power</strong>{" "}
                                            gives access to creating and
                                            sustaining a proposal.
                                        </li>
                                        <li>
                                            The <strong>voting power</strong> is
                                            used to vote for or against existing
                                            proposals.
                                        </li>
                                    </ul>
                                    <br />
                                    <p>
                                        Any user can choose to delegate one or
                                        both of the governance powers associated
                                        with a token, either through our
                                        governance portal or programmatically.
                                    </p>
                                    <br />
                                    <br />
                                    <h3 className="font-weight-bold line-height-3 font-size-md">
                                        Governance Proposals
                                    </h3>
                                    <br />
                                    <br />
                                    <p>
                                        The steps for a successful governance
                                        proposal are:
                                    </p>
                                    <br />
                                    <ol>
                                        <li className="mb-20 font-weight-bold">
                                            <p>
                                                &nbsp;Creating a Unifyd Request for
                                                Comments (PRC) and receiving
                                                community input.
                                            </p>
                                        </li>
                                        <li className="mb-20 font-weight-bold">
                                            <p>
                                                &nbsp;If the PRC is
                                                non-contentious, then submitting
                                                a Unifyd Improvement Proposal
                                                (PIP).
                                            </p>
                                        </li>
                                        <li className="font-weight-bold">
                                            <p>
                                                &nbsp;The PIP, including the
                                                necessary payloads, are
                                                forwarded to the protocol for a
                                                governance vote.
                                            </p>
                                        </li>
                                    </ol>
                                    <br />
                                    <p>
                                        If successful, the PIP's payload will be
                                        automatically executed and implemented.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Tokenomics */}
                    <section>
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Tokenomics</span>
                                    </h2>
                                    <h3 className="font-weight-bold line-height-3 font-size-md mb-40">
                                        Triple Token Mechanism
                                    </h3>
                                    <p>
                                        The Unifyd Ecosystem is supported by a
                                        unique triple token mechanism that is
                                        designed to sustain its inception and
                                        long-term growth in a decentralized and
                                        fair process. The three tokens are
                                        $ALPHA, $UNIFY, and $PSI. Seeing the
                                        evolution of other networks and
                                        ecosystems, we've realized that single
                                        token economics can't answer the needs
                                        of a growing system and maintain all
                                        token holders' interests. As a result,
                                        we came up with the following tokens
                                        composition:
                                    </p>
                                    <br />
                                    <br />
                                    <h4 className="font-weight-bold line-height-3 font-size-sm mb-20">
                                        The Official Token $UNIFY
                                    </h4>
                                    <p>
                                        <strong className="d-block mb-10">
                                            Max Supply: 1,000,000,000 $UNIFY
                                        </strong>
                                        The $UNIFY token started with no pre-farm
                                        nor pre-sale. $UNIFY tokens are minted
                                        linearly beginning from 1 August 2021
                                        and will end approximately during July
                                        2021 (8 $UNIFY/block: approximately
                                        230,400 $UNIFY daily).
                                        <br />
                                        <br />
                                        To ensure fair distribution of $UNIFY,
                                        only vault users will be able to mine
                                        $UNIFY tokens in addition to the yields
                                        from auto-compounding vaults.
                                        <br />
                                        <br />
                                        $UNIFY token can stake their tokens to
                                        earn $PSI governance tokens, provide
                                        liquidity for LP rewords, and get
                                        discounts on fees using the different
                                        products in the ecosystem.
                                        <br />
                                        <br />
                                        <strong>Buy-back Mechanism:</strong> 10%
                                        of fees on users' profits are used to
                                        perform market buy-backs of $UNIFY tokens.
                                    </p>
                                    <br />
                                    <br />
                                    <h4 className="font-weight-bold line-height-3 font-size-sm mb-20">
                                        Distribution Breakdown
                                    </h4>
                                    <div className={styles.chartContainer}>
                                        <TokenomicsPie></TokenomicsPie>
                                    </div>
                                    <br />
                                    <br />
                                    <ul>
                                        <li className="mb-20">
                                            <strong>
                                                80% are fairly distributed
                                            </strong>{" "}
                                            through the vault token mining
                                            program
                                        </li>
                                        <li>
                                            <strong>
                                                20% are dedicated to the
                                                ecosystem
                                            </strong>{" "}
                                            to ensure sustainable and rapid
                                            innovation. It may also be used for
                                            future incentives of cross-chain
                                            vaults and other products in the Unifyd
                                            ecosystem
                                        </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <h4 className="font-weight-bold line-height-3 font-size-sm mb-20">
                                        The Governance Token $PSI
                                    </h4>
                                    <p>
                                        <strong className="d-block mb-10">
                                            Initial Supply: 0 $PSI
                                        </strong>
                                        The $PSI token started with no pre-farm
                                        nor pre-sale. $PSI tokens can only be
                                        earned by staking $UNIFY tokens in the
                                        Safety Module.
                                        <br />
                                        <br />
                                        The $PSI token is the governance token
                                        of the Unifyd Ecosystem and gives its
                                        owners' governance powers and the
                                        ability to influence the future of our
                                        system. $PSI token holders earn a share
                                        of the profits of the ecosystem.
                                    </p>
                                    <br />
                                    <br />
                                    <h4 className="font-weight-bold line-height-3 font-size-sm mb-20">
                                        The Early Adopters Token $ALPHA
                                    </h4>
                                    <p>
                                        <strong className="d-block mb-10">
                                            Total Supply: 1000 $ALPHA
                                        </strong>
                                        The $ALPHA token was only offered for
                                        early adopters of the Unifyd Ecosystem.
                                        $ALPHA token holders supported the
                                        bootstrap of our community and the first
                                        products in our ecosystem.
                                        <br />
                                        <br />
                                        $ALPHA token holders have exclusive
                                        rights to a share of the profits from
                                        our flash loan products.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* Summary */}
                    <section className="pb-60">
                        <Container>
                            <div className="row justify-content-center py-60">
                                <div className="col-12 col-md-11 col-lg-10">
                                    <h2 className="paper-title font-weight-bold line-height-3 font-size-lg mb-60">
                                        <span>Summary</span>
                                    </h2>
                                    <p>
                                        The Unifyd Ecosystem is the first DeFi
                                        platform that solves all the evident
                                        issues and problems with current DeFi
                                        Projects. The Triple token mechanism
                                        makes sure that early adopters of the
                                        platform don't have an advantage over
                                        members who join us in the future. In
                                        addition, Unifyd is the only platform that
                                        offers its community of token holders a
                                        revenue share, and it's the only
                                        platform out there today which is
                                        user-centric by design. The Unifyd
                                        Ecosystem is the only DeFi project that
                                        takes a user-first approach to DeFi,
                                        ensuring every feature is developed with
                                        the most simple user in mind. The Unifyd
                                        Ecosystem is the first DeFi platform
                                        that makes a bankless future possible in
                                        reality and is the only platform with a
                                        clear way and approach for achieving it.
                                        <br />
                                        <br />
                                        We hope you join us on our journey to a
                                        free and open financial system. It's the
                                        interest of all of us to break through
                                        the walled gardens of traditional
                                        finance and claim the financial freedom
                                        we once had in our smaller communities.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
                <Footer />
            </>
        );
    }
}

export default Whitepaper;

export const WhitepaperQuery = graphql`
    query Whitepaper {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
