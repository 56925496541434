import React from "react";
import { ResponsivePie } from "@nivo/pie";
import * as styles from "./tokenomics-pie-chart.module.scss";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered..
const TokenomicsPie = () => {
    const data = [
        {
            id: "Ecosystem",
            label: "Ecosystem",
            value: 200000000,
            color: "hsl(129, 70%, 50%)",
        },
        {
            id: "Public",
            label: "Public",
            value: 800000000,
            color: "hsl(351, 70%, 50%)",
        },
    ];
    const Tooltip = (value) => <span>{{ value }}</span>;
    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeInnerRadiusOffset={5}
            activeOuterRadiusOffset={5}
            colors={[
                "#C8E5FC",
                "#74BDF7",
                "#2196F3",
                "#1971B6",
                "#114B79",
                "#08253D",
                "#EFEFEF",
            ]}
            borderColor="#000000"
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={5}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            arcLabel={function (e) {
                return (e.value / 1000000000) * 100 + "%";
            }}
            tooltip={function (e) {
                var t = e.datum;
                return (
                    <div className={styles.tooltip}>
                        <div
                            className={styles.tooltipColor}
                            style={{ background: t.color }}
                        ></div>
                        <span className={styles.tooltipValue}>
                            {t.value.toLocaleString()}
                        </span>
                    </div>
                );
            }}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.15)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.15)",
                    rotation: -45,
                    lineWidth: 5,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "Ecosystem",
                    },
                    id: "lines",
                },
                {
                    match: {
                        id: "Public",
                    },
                    id: "lines",
                },
            ]}
            motionConfig={{
                mass: 1,
                tension: 200,
                friction: 20,
                clamp: false,
                precision: 0.01,
                velocity: 0,
            }}
            legends={[
                {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: "#000",
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default TokenomicsPie;
